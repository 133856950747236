@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .step-divider {
    @apply h-4 w-[2px] bg-gray-300;
    @apply my-2 ml-3;
  }
}
