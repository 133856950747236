@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./fonts/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'InterBold';
  src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'InterLight';
  src: local('Inter'), url(./fonts/Inter-Light.ttf) format('truetype');
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

progress::-webkit-progress-value {
  background: linear-gradient(87.42deg, #4553d3 4.32%, #a8e18d 100%);
}

progress::-webkit-progress-bar {
  background-color: #e5e7eb;
}

.notfound {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
    radial-gradient(71.37% 71.37% at 52.5% 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        103.6% 147% at 47.08% -25.75%,
        #a8e18d 0%,
        #e8fde4 22.92%,
        #b1b7ee 42.19%,
        rgba(53, 50, 209, 0.49) 76.04%,
        #a5a4e2 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.swap {
  background: radial-gradient(
    160.41% 438.46% at 75% -88.97%,
    rgba(168, 225, 141) 0%,
    rgba(69, 83, 211) 22.92%,
    rgba(168, 225, 141) 57.81%,
    rgba(69, 83, 211) 100%
  );
}

.pool {
  background: radial-gradient(78.61% 158.8% at 11.5% -10.66%, #4553d3 0%, #a8e18d 100%);
}

.pending {
  background: linear-gradient(87.42deg, #4553d3 4.32%, #a8e18d 100%);
}

.approval {
  background: radial-gradient(
      194.89% 495.15% at -15.69% 42.31%,
      #a8e18d 0%,
      #4553d3 22.92%,
      #a8e18d 57.81%,
      #4553d3 100%
    )
    top right/200% 200%;
}

.rewards {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.53) 0%, rgba(255, 255, 255, 0) 100%);
}

.badge {
  background: radial-gradient(169.07% 354% at 10.31% 45.15%, #a8e18d 0%, #4553d3 22.92%, #a8e18d 57.81%, #4553d3 100%);
}

.error {
  border-radius: 25px;
  background: var(
    --error-background,
    radial-gradient(
      247.64% 140.2% at -0% 0%,
      rgba(220, 38, 38, 0.04) 0%,
      rgba(220, 38, 38, 0.01) 31.25%,
      rgba(220, 38, 38, 0.07) 66.67%,
      rgba(220, 38, 38, 0) 100%
    ),
    #fff
  );
  box-shadow: 0px 15px 25px 0px rgba(69, 83, 211, 0.15);
}

.alert {
  background: radial-gradient(
      102.2% 225.57% at 0% 0%,
      rgba(220, 38, 38, 0.044) 0%,
      rgba(220, 38, 38, 0.008) 31.25%,
      rgba(220, 38, 38, 0.066) 66.67%,
      rgba(220, 38, 38, 0) 100%
    )
    #ffffff;
}

body {
  background-color: #f0f1fb;
}

/* width */
::-webkit-scrollbar {
  width: 4px; /* width of vertical scrollbar */
  height: 4px; /* width of horizontal scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
  margin: 16px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 5px;
}

html,
body {
  scroll-behavior: smooth;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

@keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

.animate-shake {
  animation: shake 0.5s linear;
}
