@tailwind components;

@layer components {
  .accordion-trigger {
    @apply flex items-center justify-between gap-2;
  }

  .accordion-trigger > h3 {
    @apply text-[18px];
  }

  .accordion-trigger[data-state='open'] > .accordion-chevron {
    transform: rotate(180deg);
  }

  .accordion-chevron {
    transition: transform 300ms;
    @apply h-5 w-5;
  }

  .accordion-header {
    @apply flex items-center justify-between;
  }

  .accordion-header-pill {
    @apply w-fit rounded-full bg-black-900 px-4 py-[10px] font-subheader text-xs text-white;
  }

  .accordion-item {
    @apply border-b border-gray-200 px-6 py-6;
  }

  .accordion-content {
    @apply overflow-hidden py-2;
  }

  .accordion-content[data-state='open'] {
    animation: slideDown 300ms ease-in-out;
  }

  .accordion-content[data-state='closed'] {
    animation: slideUp 300ms ease-in-out;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

  .pool-type-card,
  .pool-fee-card {
    @apply relative flex w-full flex-col items-start gap-1 rounded-md border border-gray-100 px-3 py-2 md:w-1/2;
  }

  .pool-fee-card {
    @apply md:w-1/4;
  }

  .pool-type-card[data-state='selected'],
  .pool-fee-card[data-state='selected'] {
    @apply border-primary-500;
  }

  .pool-type-card > p,
  .pool-fee-card > p {
    @apply text-xs text-gray-500;
  }

  .pool-type-card > h5,
  .pool-fee-card > h5,
  .pool-fee-card .header {
    @apply mb-1 font-subheader text-base;
  }
  .pool-type-card[data-state='selected'] > h5,
  .pool-fee-card[data-state='selected'] > h5,
  .pool-fee-card[data-state='selected'] .header {
    @apply text-primary-500;
  }

  .pool-type-card > .check-icon,
  .pool-fee-card > .check-icon {
    @apply absolute right-1 top-2 hidden h-5 w-5 text-primary-500;
  }
  .pool-type-card[data-state='selected'] > .check-icon,
  .pool-fee-card[data-state='selected'] > .check-icon {
    @apply block;
  }

  .pool-fee-custom-popover {
    @apply mb-3 max-w-[235px] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95;
  }

  .pool-fee-popover-content {
    @apply flex h-[174px] w-[234px] flex-col gap-2 rounded-3xl bg-white p-5 text-sm shadow-xl;
  }

  .pool-fee-popover-content input {
    @apply h-[62px] rounded-md border-[1.5px] border-primary-500 p-2 text-left text-primary-500;
  }

  .pool-fee-popover-content h6 {
    @apply text-sm uppercase text-gray-800;
  }

  .error-message {
    @apply rounded-md bg-red-100 px-2 py-3 text-xs text-red-700;
  }

  .info-message {
    @apply rounded-md bg-gray-100 px-2 py-3 text-xs text-gray-500;
  }
}
